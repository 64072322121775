import {
  BuyPropertyStatus,
  FinancialStatus,
  PropertyTypes,
  SellPropertyStatus,
} from 'constants/property';

export const propertyTypeLabels = {
  [PropertyTypes.SECTION]: 'Section',
  [PropertyTypes.UNIT]: 'Unit',
  [PropertyTypes.APARTMENT]: 'Apartment',
  [PropertyTypes.HOUSE]: 'House',
  [PropertyTypes.COMMERCIAL_PROPERTY]: 'Commercial Property',
  [PropertyTypes.AGRICULTURAL_PROPERTY]: 'Agricultural Property',
  [PropertyTypes.LIFESTYLE_BARELAND]: 'Lifestyle Bareland',
  [PropertyTypes.LIFESTYLE_DWELLING]: 'Lifestyle Dwelling',
  [PropertyTypes.HORTICULTURAL_PROPERTY]: 'Horticultural Property',
};

export const sellingColumns = [
  'Property Type',
  'Location',
  'Price',
  'Matches',
  'Status',
];

export const sellStatusLabel = {
  [SellPropertyStatus.FOR_SALE]: 'For Sale',
  [SellPropertyStatus.PRE_REGISTRATION]: 'Pre-registration',
  [SellPropertyStatus.SOLD]: 'Sold',
  [SellPropertyStatus.WITHDRAWN]: 'Withdrawn - Remove from List',
  [SellPropertyStatus.DELETED]: 'Withdrawn',
};

export const buyingColumns = [
  'Property Type',
  'Location',
  'Price',
  'Matches',
  'Finance Status',
  'Status',
];

export const buyStatusLabel = {
  [BuyPropertyStatus.LOOKING_TO_BUY]: 'Looking',
  [BuyPropertyStatus.NO_LONGER_LOOKING]: 'No Longer Looking',
  [BuyPropertyStatus.PURCHASED]: 'Purchased',
  [BuyPropertyStatus.DELETED]: 'Deleted',
};

export const buyFinanceLabel = {
  [FinancialStatus.FUNDS_AVAILABLE]: 'Funds Available',
  [FinancialStatus.PRE_APPROVED]: 'Pre-approved',
  [FinancialStatus.SUBJECT_TO_PROPERTY_SALE]: 'Subject to Property Sale',
};

export const directColumns = [
  'Property Type',
  'Location',
  'Price',
  'Status',
  'Added Property Code On',
];

export const facilitateColumns = [
  'Property Type',
  'Location',
  'Seller',
  'Status',
];

export const logColumns = [
  'Event',
  'Device Type',
  'Device Name',
  'OS Versions',
  'App Version',
  'Executed By',
  'Executed On',
];
