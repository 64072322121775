import { SellListingSummary } from 'types/Property';

export type SellListingProps = {
  data: SellListingSummary;
  refetch: () => void;
};

export type SellListingGeneratedProps = {
  data: SellListingSummary;
  onUnlock: () => void;
  onLock: () => void;
  onVerify: () => void;
  onUnverify: () => void;
  refetch: () => void;
};

export enum MODE {
  DEFAULT = 'default',
  EDIT = 'edit',
  MATCHES = 'matches',
  PREREG_ACTIVITIES = 'prereg_activities',
  OPEN_HOMES = 'open_homes',
  ADVISORY = 'advisory',
  AD_ACTIVITIES = 'ad_activities',
}
