import styled from '@emotion/styled';

import { LabelPublicProps } from 'components/primitives/Label/Label.props';

export const Container = styled.div<LabelPublicProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.shade4};
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  padding: ${({ padding }) => padding || '5px 14px'};
`;
