import React, { useMemo, useState } from 'react';

import { Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { routesSettings } from 'constants/routes';

import BuyListingEdit from '../BuyListingEdit';
import BuyListingMatches from '../BuyListingMatches';
import { BuyListingProps } from './BuyListing.props';
import { MODE } from './BuyListing.utils';
import BuyListingView from './BuyListing.view';

const BuyListingContainer = (props: BuyListingProps): JSX.Element => {
  const history = useHistory();
  const [mode, setMode] = useState<MODE>(MODE.DEFAULT);
  const [matchesTab, setMatchesTab] = useState('matches');
  const onViewProfile = () => {
    if (props.data.user)
      history.push({
        pathname: routesSettings.MAIN_USERS.path,
        search: `?id=${props.data.user.id}`,
      });
  };

  const matchesSummary = useMemo(
    () =>
      matchesTab === 'matches'
        ? props.data?.matches.matched.data.map((m) => [
            m.seller ? `${m.seller.firstName} ${m.seller.lastName}` : '',
            `${m.matchPercentage}%`,
            format(new Date(m.createdAt), 'dd/MM/y HH:mm:ss a'),
          ])
        : props.data?.matches.direct.data.map((m) => [
            m.seller ? `${m.seller.firstName} ${m.seller.lastName}` : '',
            format(new Date(m.createdAt), 'dd/MM/y HH:mm:ss a'),
          ]),
    [matchesTab, props.data?.matches]
  );

  if (!props.data) {
    return <Flex>Something went wrong</Flex>;
  }

  if (mode === MODE.EDIT)
    return (
      <BuyListingEdit
        data={props.data}
        onEndEdit={() => {
          props.refetch();
          setMode(MODE.DEFAULT);
        }}
      />
    );

  if (mode === MODE.MATCHES)
    return (
      <BuyListingMatches
        id={props.data.id}
        onBack={() => {
          setMode(MODE.DEFAULT);
        }}
      />
    );

  return (
    <BuyListingView
      {...props}
      mode={mode}
      setMode={setMode}
      onViewProfile={onViewProfile}
      setMatchesTab={setMatchesTab}
      matchesTab={matchesTab}
      matchesSummary={matchesSummary}
    />
  );
};

export default BuyListingContainer;
