enum ROUTES {
  ERROR = 'ERROR',
  // AUTH ROUTE ENTRY
  AUTH_LOGIN = 'AUTH_LOGIN',
  // MAIN ROUTE ENTRY
  MAIN_DRAFTS = 'MAIN_DRAFTS',
  MAIN_VOUCHERS = 'MAIN_VOUCHERS',
  MAIN_USERS = 'MAIN_USERS',
  MAIN_AGENT_APPLICATIONS = 'MAIN_AGENT_APPLICATIONS',
  MAIN_LISTINGS = 'MAIN_LISTINGS',
  MAIN_DASHBOARD = 'MAIN_DASHBOARD',
  MAIN_CHARITIES = 'MAIN_CHARITIES',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/',
    getPath: () => `/`,
  },
  // MAIN ROUTE SETTINGS ENTRY
  [ROUTES.MAIN_DRAFTS]: {
    name: 'Drafts',
    path: '/drafts',
    getPath: () => `drafts`,
  },
  [ROUTES.MAIN_VOUCHERS]: {
    name: 'Vouchers',
    path: '/vouchers',
    getPath: () => `/vouchers`,
  },
  [ROUTES.MAIN_USERS]: {
    name: 'Users',
    path: '/users',
    getPath: () => `/users`,
  },
  [ROUTES.MAIN_LISTINGS]: {
    name: 'Listings',
    path: '/listings',
    getPath: () => `/listings`,
  },
  [ROUTES.MAIN_AGENT_APPLICATIONS]: {
    name: 'AgentApplications',
    path: '/agent-applications',
    getPath: () => `/agent-applications`,
  },
  [ROUTES.MAIN_CHARITIES]: {
    name: 'Charities',
    path: '/charities',
    getPath: () => '/charities',
  },
});

export default ROUTES;
